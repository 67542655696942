import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      viewBox="0 0 60 54.9"
      {...props}
    >
      <style>
        {
          ".prefix__st1{fill:#5d132a}.prefix__st3{fill:#fff}.prefix__st4{fill:none}"
        }
      </style>
      <path
        d="M57.5 28.2c0-3.5-.8-10.6-5.3-16.5l1.2-1.7c1.8-2.5 1.3-6.1-1.3-7.9C49.6.3 46 .8 44.2 3.4l-1.1 1.5C39.6 3.4 35.3 2.6 30 2.6s-9.6.9-13.1 2.3l-1.1-1.5C14 .9 10.4.3 7.9 2.1 5.3 4 4.8 7.5 6.6 10l1.2 1.7c-4.5 5.8-5.3 13-5.3 16.5 0 6.6-1.5 9.6-1.5 9.6 3.3 17 29 16.1 29 16.1s25.7.9 29-16.1c0 0-1.5-3-1.5-9.6z"
        fill="#f6bc59"
      />
      <path
        className="prefix__st1"
        d="M30.8 54.9h-.9c-1 0-26.5.7-29.9-17 0-.2 0-.5.1-.7 0 0 1.4-2.9 1.4-9.1 0-2.9.5-10.2 5-16.5l-.7-.9c-2.2-3-1.5-7.2 1.5-9.4s7.2-1.5 9.4 1.5l.6.8c3.7-1.4 8-2.1 12.8-2.1s9 .7 12.7 2.1l.6-.8c2.2-3 6.4-3.7 9.4-1.5s3.7 6.4 1.5 9.4l-.8 1.1c4.5 6.3 5 13.6 5 16.5 0 6.2 1.4 9.1 1.4 9.1.1.2.1.4.1.7C56.9 54 35.5 54.9 30.8 54.9zm-28.7-17c3.3 15.6 27.6 15 27.8 15 .3 0 24.6.6 27.9-15-.4-1-1.5-4.1-1.5-9.7 0-2.8-.5-9.9-5.1-15.8-.3-.4-.3-.9 0-1.3l1.2-1.7c1.5-2.1 1-5-1-6.5-1-.7-2.2-1-3.4-.8-1.2.2-2.2.9-2.9 1.9L44 5.5c-.3.4-.8.5-1.2.3-3.6-1.5-7.9-2.3-12.7-2.3s-9.1.8-12.7 2.3c-.4.2-1 0-1.2-.3L14.9 4c-.7-1-1.8-1.7-3-1.9s-2.4.1-3.4.8C6.4 4.4 6 7.3 7.5 9.4l1.2 1.7c.3.4.3.9 0 1.3-4.6 5.9-5.1 13.1-5.1 15.8 0 5.6-1.1 8.7-1.5 9.7z"
      />
      <defs>
        <path
          id="prefix__SVGID_1_"
          d="M57.9 37.9c-.4-1-1.4-4.1-1.4-9.7 0-2.8-.5-9.9-5.1-15.8-.3-.4-.3-.9 0-1.3l1.2-1.7c.7-1 1-2.2.8-3.4-.2-1.2-.9-2.3-1.9-3s-2.2-1-3.4-.8c-1.2.1-2.3.8-3 1.8L44 5.5c-.3.4-.8.5-1.2.3-3.6-1.5-7.9-2.3-12.7-2.3s-9.1.8-12.7 2.3c-.4.2-1 0-1.2-.3L14.9 4c-.7-1-1.8-1.7-3-1.9s-2.4.1-3.4.8-1.7 1.8-1.9 3c-.2 1.2.1 2.4.8 3.4L8.6 11c.3.4.3.9 0 1.3C4 18.2 3.5 25.4 3.5 28.1c0 5.5-1 8.7-1.4 9.7 3.3 15.6 27.6 15 27.8 15h.1c.3 0 24.5.7 27.9-14.9z"
        />
      </defs>
      <clipPath id="prefix__SVGID_2_">
        <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__SVGID_2_)">
        <path
          className="prefix__st3"
          d="M30 38c-8.5 0-15.8 4.7-19.6 11.6 8.5 4.6 19.6 4.2 19.6 4.2s11.1.4 19.6-4.2C45.8 42.8 38.5 38 30 38z"
        />
      </g>
      <path
        className="prefix__st1"
        d="M42.6 27.7c.2.3.2.7.2 1.1v4.8c0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5v-3.8l6.8-2.1zM17.4 27.7c-.1.4-.2.8-.2 1.2v4.8c0 1.9 1.6 3.5 3.5 3.5s3.5-1.6 3.5-3.5v-3.8l-6.8-2.2z"
      />
      <path
        className="prefix__st3"
        d="M29.9 37.8c0 1.4-.9 2.4-2.3 2.4s-2.5-.7-2.5-2.1c0-1.4 1.8-2.7 3.2-2.7 2.2 0 1.6 1 1.6 2.4z"
      />
      <path
        className="prefix__st1"
        d="M27.7 40.7c-1.9 0-3.1-1.1-3.1-2.7 0-1.7 2.1-3.2 3.8-3.2.9 0 1.5.2 1.9.6.5.5.4 1.1.4 1.8v.7c-.2 1.6-1.3 2.8-3 2.8zm.7-4.7c-1.1 0-2.6 1-2.6 2 0 1.1 1 1.5 1.9 1.5 1 0 1.7-.7 1.7-1.8V37c0-.4.1-.8 0-.9-.1 0-.3-.1-1-.1z"
      />
      <g>
        <path
          className="prefix__st3"
          d="M30.1 37.8c0 1.4.9 2.4 2.3 2.4s2.4-.8 2.4-2.2-1.8-2.7-3.2-2.7c-2.1.1-1.5 1.1-1.5 2.5z"
        />
        <path
          className="prefix__st1"
          d="M32.3 40.7c-1.7 0-2.9-1.2-2.9-3V37c0-.6-.1-1.3.4-1.8.4-.4.9-.6 1.9-.6 1.7 0 3.8 1.5 3.8 3.2-.1 1.8-1.3 2.9-3.2 2.9zm-.6-4.7c-.8 0-.9.1-1 .2-.1.1-.1.5 0 .9v.7c0 1.1.6 1.8 1.7 1.8.9 0 1.9-.4 1.9-1.5-.1-1.1-1.6-2.1-2.6-2.1z"
        />
      </g>
      <g>
        <path
          className="prefix__st1"
          d="M30 33.9s-1.9 0-1.9 1.1c0 .8 1.4 1.9 1.9 1.9s1.9-1.1 1.9-1.9c0-1.2-1.9-1.1-1.9-1.1z"
        />
      </g>
      <g>
        <path
          className="prefix__st1"
          d="M34.5 21.9h-9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h9c.8 0 1.5.7 1.5 1.5 0 .9-.7 1.5-1.5 1.5zM34.5 13.3h-9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h9c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zM36.9 17.6H23.1c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h13.7c.8 0 1.5.7 1.5 1.5.1.8-.6 1.5-1.4 1.5z"
        />
        <path
          className="prefix__st1"
          d="M30 21.6c-.8 0-1.5-.7-1.5-1.5V12c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v8.1c0 .9-.7 1.5-1.5 1.5z"
        />
      </g>
      <g>
        <circle className="prefix__st1" cx={11.1} cy={6.6} r={2.2} />
      </g>
      <g>
        <circle className="prefix__st1" cx={49} cy={6.6} r={2.2} />
      </g>
      <g>
        <path className="prefix__st4" d="M2.8 32.4l6.9 2.1" />
        <path
          className="prefix__st1"
          d="M9.7 36c-.1 0-.3 0-.4-.1l-6.9-2.1c-.8-.2-1.2-1.1-1-1.9.2-.8 1.1-1.2 1.9-1l6.9 2.1c.8.2 1.2 1.1 1 1.9-.3.6-.9 1.1-1.5 1.1z"
        />
        <g>
          <path className="prefix__st4" d="M1.9 36.6L6.4 38" />
          <path
            className="prefix__st1"
            d="M6.4 39.5c-.2 0-.3 0-.5-.1L1.5 38c-.8-.3-1.2-1.1-.9-1.9.3-.8 1.1-1.2 1.9-.9L7 36.7c.8.3 1.2 1.1.9 1.9-.3.5-.8.9-1.5.9z"
          />
        </g>
        <g>
          <path className="prefix__st4" d="M57.2 32.4l-6.9 2.1" />
          <path
            className="prefix__st1"
            d="M50.3 36c-.6 0-1.2-.4-1.4-1.1-.2-.8.2-1.6 1-1.9l6.9-2.1c.8-.2 1.6.2 1.9 1 .2.8-.2 1.6-1 1.9l-6.9 2.1c-.2 0-.4.1-.5.1z"
          />
          <g>
            <path className="prefix__st4" d="M58.1 36.6L53.6 38" />
            <path
              className="prefix__st1"
              d="M53.6 39.5c-.6 0-1.2-.4-1.4-1-.3-.8.2-1.6.9-1.9l4.5-1.5c.8-.3 1.6.2 1.9.9.3.8-.2 1.6-.9 1.9l-4.5 1.5c-.2.1-.4.1-.5.1z"
            />
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default Icon;
