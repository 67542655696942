import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 63.3 63.3" {...props}>
      <defs>
        <circle id="prefix__a" cx={31.6} cy={31.6} r={31.6} />
      </defs>
      <use xlinkHref="#prefix__a" overflow="visible" fill="#e8eae9" />
      <g>
        <path
          d="M47.8 39.7c-.1 0-.2.1-.3.1-.4 0-.8-.3-.9-.7-.2-.5.1-1.1.6-1.3l3.3-1.1c-.1-.3-.1-.6-.2-1l-4.7 1.6c-.1 0-.2.1-.3.1-.4 0-.8-.3-.9-.7-.2-.5.1-1.1.6-1.3l5.1-1.8v-1.5c0-2.4-.5-7.1-3.5-11l.8-1.1c1.2-1.7.9-4.1-.8-5.3-1.7-1.2-4.1-.9-5.3.8l-.7 1c-2.3-1-5.2-1.6-8.8-1.6-3.6 0-6.5.6-8.8 1.6l-.7-1a3.72 3.72 0 00-5.3-.8 3.72 3.72 0 00-.8 5.3l.8 1.1c-3 3.9-3.5 8.7-3.5 11v1.5l5.1 1.8c.5.2.8.8.6 1.3-.1.4-.5.7-.9.7-.1 0-.2 0-.3-.1l-5-1.6c-.1.4-.1.7-.2 1l3.3 1.1c.5.2.8.7.6 1.3-.1.4-.5.7-.9.7-.1 0-.2 0-.3-.1l-3.2-1.1c2.3 11.3 19.4 10.7 19.4 10.7s17 .6 19.3-10.7l-3.2 1.1zM19.1 19.2c-.8.1-1.5-.5-1.6-1.4-.1-.8.5-1.5 1.4-1.6.8-.1 1.5.5 1.6 1.4 0 .8-.6 1.5-1.4 1.6zM27.7 36c0 1.3-1.1 2.4-2.4 2.4S23 37.3 23 36v-3.3c0-.3.1-.6.1-.8l4.6 1.5V36zm6.9-8.1h-6c-.6 0-1-.4-1-1s.4-1 1-1h2V25H27c-.6 0-1-.4-1-1s.4-1 1-1h3.6v-.9h-2c-.6 0-1-.4-1-1s.4-1 1-1h6c.6 0 1 .4 1 1s-.4 1-1 1h-2v.9h3.6c.6 0 1 .4 1 1s-.4 1-1 1h-3.6v.9h2c.6 0 1 .4 1 1s-.4 1-1 1zm5.7 8.1c0 1.3-1.1 2.4-2.4 2.4-1.3 0-2.4-1.1-2.4-2.4v-2.5l4.6-1.5c.1.3.1.5.1.8V36zm2.5-18.2c-.1-.8.5-1.5 1.4-1.6.8-.1 1.5.5 1.6 1.4.1.8-.5 1.5-1.4 1.6-.8 0-1.5-.6-1.6-1.4z"
          fill="#bbbfc3"
        />
      </g>
    </Svg>
  );
};

export default Icon;
